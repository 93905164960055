// import { flow } from 'lodash';
import model from './model';

export default model.createController(({ $bind, $widget, $w, flowAPI }) => {
  const { location, window } = flowAPI.controllerConfig.wixCodeApi;
  const { isEditor, isEditorX } = flowAPI.environment;
  const appSlug = 'client-check-in';

  const startTime = () => {
    const today = new Date();
    $w('#hedearTime').text = today.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    setTimeout(startTime, 1000);
  };

  const hideHedear = ({ shouldHide }) => {
    shouldHide ? $w('#hedear').hide() : $w('#hedear').show();
  };

  const createQrCode = async (url) => {
    const apiUrl = `https://api.qrserver.com/v1/create-qr-code/?size=300&data=${url}`;
    return fetch(apiUrl);
  };
  const firstState = isEditor || isEditorX ? 'sessionList' : 'login';
  $w('#multiStateBox').changeState(firstState);

  return {
    pageReady: async () => {
      const { query } = location;
      if (query.searchOnly === 'true') {
        hideHedear({ shouldHide: true });
        $w('#multiStateBox').changeState('search');
      }
      $widget.fireEvent('widgetLoaded', {});
      hideHedear({ shouldHide: true });
      startTime();
      $w('#hedearDate').text = new Date().toDateString();
      const qrImg = await createQrCode(
        `${location.baseUrl}/${appSlug}?searchOnly=true`,
      );
      $w('#qrCode').src = qrImg.url;

      $w('#loginEnterButton').onClick(() => {
        hideHedear({ shouldHide: false });
        $w('#multiStateBox').changeState('sessionList');
        window.openLightbox('Error Modal', {});
      });

      $w('#signoutButton').onClick(() => {
        hideHedear({ shouldHide: true });
        $w('#scanScreenIcon').show();
        $w('#multiStateBox').changeState('login');
      });

      $w('#scanScreenIcon').onClick(() => {
        $w('#multiStateBox').changeState('scanToCheckin');
        $w('#scanScreenIcon').hide();
      });

      $w('#sessionListSelectButtonItem').onClick(() => {
        window.openLightbox('Details Modal', {});
        $w('#multiStateBox').changeState('memberList');
      });

      $w('#memberListBackButton').onClick(() => {
        $w('#multiStateBox').changeState('sessionList');
      });

      $w('#memberListCheckinButton').onClick(() => {
        $w('#attandanceMSB').changeState('checkedIn');
        // flowAPI.controllerConfig.wixCodeApi.window.
      });

      $w('#memberListCheckedInButton').onClick(() => {
        $w('#attandanceMSB').changeState('checkIn');
      });
    },
    exports: {},
  };
});
