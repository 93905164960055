import model from './model';

export default model.createController(({ $bind, $w, $widget, flowAPI }) => {
  const { window } = flowAPI.controllerConfig.wixCodeApi;
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});

      $w('#bookingsModalCloseButton').onClick(() => {
        // @ts-expect-error
        window.lightbox.close();
      });
    },
    exports: {},
  };
});
