
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  
            
import wrapController0 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller0 from '/home/builduser/work/f35f652dcafd0a82/packages/client-check-in-widget/src/components/BookingsModal/viewer.controller.ts';
            import * as _controllerExport0 from '/home/builduser/work/f35f652dcafd0a82/packages/client-check-in-widget/src/components/BookingsModal/model.ts';
            var controllerExport0 = _controllerExport0;
            

            
import wrapController1 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller1 from '/home/builduser/work/f35f652dcafd0a82/packages/client-check-in-widget/src/components/ClientCheckinWidget/viewer.controller.ts';
            import * as _controllerExport1 from '/home/builduser/work/f35f652dcafd0a82/packages/client-check-in-widget/src/components/ClientCheckinWidget/model.ts';
            var controllerExport1 = _controllerExport1;
            

            
import wrapController2 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller2 from '/home/builduser/work/f35f652dcafd0a82/packages/client-check-in-widget/src/components/ErrorModal/viewer.controller.ts';
            import * as _controllerExport2 from '/home/builduser/work/f35f652dcafd0a82/packages/client-check-in-widget/src/components/ErrorModal/model.ts';
            var controllerExport2 = _controllerExport2;
            

            
import wrapController3 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller3 from '/home/builduser/work/f35f652dcafd0a82/packages/client-check-in-widget/src/components/Widget1/viewer.controller.ts';
            import * as _controllerExport3 from '/home/builduser/work/f35f652dcafd0a82/packages/client-check-in-widget/src/components/Widget1/model.ts';
            var controllerExport3 = _controllerExport3;
            

  
  var importedApp = {};


  

    import { initState, initBind } from '@wix/yoshi-flow-editor/runtime/esm/velocycleMobx';
    import { observable } from 'mobx';
    var velocycleMobx = { initState, initBind, observable };
    


  

    import { blocksControllerService } from '@wix/yoshi-flow-editor/runtime/esm/blocks/controllerService'
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = null;

  var experimentsConfig = {"centralized":true,"scopes":[]};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/f35f652dcafd0a82/packages/client-check-in-widget/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/f35f652dcafd0a82/packages/client-check-in-widget/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "client-check-in-widget",
    biConfig: null,
    appName: "Client Check-in",
    appDefinitionId: "f3c9f6db-74bd-405d-b09b-0a222df2df60",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: controller0,
          wrap: wrapController0,
          exports: controllerExport0,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/f35f652dcafd0a82/packages/client-check-in-widget/src/components/BookingsModal/viewer.controller.ts",
          appName: "Client Check-in",
          appDefinitionId: "f3c9f6db-74bd-405d-b09b-0a222df2df60",
          projectName: "client-check-in-widget",
          componentName: "BookingsModal",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "f3c9f6db-74bd-405d-b09b-0a222df2df60-lt5qw" }, { method: controller1,
          wrap: wrapController1,
          exports: controllerExport1,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/f35f652dcafd0a82/packages/client-check-in-widget/src/components/ClientCheckinWidget/viewer.controller.ts",
          appName: "Client Check-in",
          appDefinitionId: "f3c9f6db-74bd-405d-b09b-0a222df2df60",
          projectName: "client-check-in-widget",
          componentName: "ClientCheckinWidget",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "f3c9f6db-74bd-405d-b09b-0a222df2df60-a49gu" }, { method: controller2,
          wrap: wrapController2,
          exports: controllerExport2,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/f35f652dcafd0a82/packages/client-check-in-widget/src/components/ErrorModal/viewer.controller.ts",
          appName: "Client Check-in",
          appDefinitionId: "f3c9f6db-74bd-405d-b09b-0a222df2df60",
          projectName: "client-check-in-widget",
          componentName: "ErrorModal",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "f3c9f6db-74bd-405d-b09b-0a222df2df60-ja5mg" }, { method: controller3,
          wrap: wrapController3,
          exports: controllerExport3,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/f35f652dcafd0a82/packages/client-check-in-widget/src/components/Widget1/viewer.controller.ts",
          appName: "Client Check-in",
          appDefinitionId: "f3c9f6db-74bd-405d-b09b-0a222df2df60",
          projectName: "client-check-in-widget",
          componentName: "Widget1",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "f3c9f6db-74bd-405d-b09b-0a222df2df60-x5kmw" }],
    false);

    export const createControllers = _createControllers
